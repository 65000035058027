import React from "react";
import Footer from './Footer'
import NaviBarv2 from './NaviBarv2'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles.css";
import {Container, Row, Col, Card, ListGroup, ListGroupItem, Button, Tab, Nav,
OverlayTrigger, Popover, Placeholder} from 'react-bootstrap';
import Zvonareva from "../images/team/Zvonareva.png"

export function Zvonareva_info () {
  return (
        <>
    <NaviBarv2 />
    <Container>
      <section>
        <div><img src={Zvonareva} align="left" width={320} ></img></div>
        <div class="row my-4 ps-4"><h1>Звонарева Татьяна Александровна</h1></div>
        <div class="row ps-4"><p>Младший научный сотрудник</p><hr></hr></div>
        <div class="row ps-4"><h5>📩 t.a.zvonareva@math.nsc.ru</h5>
        <h5>📩 t.zvonareva@g.nsu.ru</h5><hr></hr>
        </div>
        <div class="row">
        <div class="col-sm-5 col-md-6 ps-5">WOS Research ID:<a href="https://www.webofscience.com/wos/author/record/GQP-5052-2022">GQP-5052-2022</a></div>
        <div class="col-sm-5 offset-sm-2 col-md-6 offset-md-0 ">ID РИНЦ:<a rel="noopener" target="_blank" href="https://www.elibrary.ru/author_profile.asp?authorid=1163527">1163527</a></div>
        </div>
        <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-6 mx-auto ps-5 ">Scopus Author ID:<a href="https://www.scopus.com/authid/detail.uri?authorId=57274354900">57274354900</a><hr></hr></div>
        <div class="col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0">ORCID: <a href="https://orcid.org/0000-0002-5418-3098">0000-0002-5418-3098</a><hr></hr></div>
        </div>
        <div class="row ps-4"><p><a href="https://covid19-modeling.ru/data/Резюме ЗвонареваТА.pdf">Резюме</a></p></div>
        <div class="row ps-4"><p>1997</p></div>
        </section>
        <hr></hr>
        <div class="row">
        <div class="col-sm-5 col-md-6"><div class="my-3"><h3>Образование</h3></div>
        <p>2015-2019 г. — Студент бакалавр Новосибирского государственного университета, Россия. Название дипломной работы: Метод роя частиц численного решения обратной задачи социальных сетей.Научные руководители: член-корр. РАН, профессор С.И. Кабанихин, канд. физ.-мат. наук О.И.Криворотько.</p>
        <p>2019-2021 г. — Студент магистрант Новосибирского государственного университета, Россия. Название дипломной работы: Регуляризированный алгоритм решения задачи об источнике для диффузионно-логистической модели.Научный руководитель: канд. физ.-мат. наук О.И. Криворотько.</p>
        </div>

        <div class="col-sm-5 offset-sm-2 col-md-6 offset-md-0"><div class="my-3"><h3>Профессиональный опыт</h3></div>
        <p> 2023 г.-н.вр. — Младший научный сотрудник, Лаборатория ИИ-технологий математического моделирования биологических, социально-экономических и экологических процессов, Институт математики им. С.Л. Соболева СО РАН, Новосибирск, Россия.</p>
        <p> 2023 г.-н.вр. — Младший научный сотрудник, Международный математический центр, Институт математики им. С.Л. Соболева СО РАН, Новосибирск, Россия.</p>
        <p> 2020 г.-н.вр. — Ассистент, Кафедра высшей математики физического факультета, Новосибирский государственный университет, Новосибирск, Россия.</p>
        <p> 2021 г.-2022 г. — Инженер, Лаборатория методов создания, исследования и идентификации математических моделей естествознания, Новосибирский государственный университет, Новосибирск, Россия.</p>
        <p> 2020 г.-2023 г. — Инженер, Институт вычислительной математики и математической геофизики СО РАН, Новосибирск, Россия.</p>
        <p> 2020 г. — Инженер, Международный научно-образовательный математический центр, Новосибирский государственный университет, Новосибирск, Россия.</p>
</div>
        </div>
        <hr></hr>
        <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-6 mx-auto"><div class="my-3"><h3>Преподавание</h3></div>
        <p>Векторный и тензорный анализ - семинары, 3 курс физического факультета НГУ.</p>
        <Button href="/Krivorotko_Zvonareva_teaching" className="text-white shadow4 mp_info" variant="info" size="sm">
            <div style={{fontSize: 16}}><b>Материалы</b></div></Button>
   
</div>





        <div class="col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0"><div class="my-3"><h3>Профессиональные интересы</h3></div>
        <p>Математическое моделирование.</p>
        <p>Обратные задачи.</p>
        <p>Идентифицируемость.</p>
        <p>Оптимизация.</p>
        <p>Социальные процессы.</p>
        <p>Регуляризация.</p>
        </div>
        <hr></hr>
        <div class="row">
          <div class="my-3"><h3>Исследовательские проекты и гранты</h3></div>
          <p>РНФ 18-71-10044 «Суперкомпьютерный анализ социальных, эпидемиологических и экономических процессов. Теория, алгоритмы и комплекс программ» (2018-2023) — исполнитель.</p>
          <p>РФФИ 18-31-20019 «Прямые и обратные задачи социальных процессов: теория, алгоритмы и комплекс программ» (2018-2020) — исполнитель.</p>
          <p>Грант Президента Российской Федерации MK-814.2019.1 «Анализ и применение методов машинного обучения в обратных задачах с использованием параллельных вычислений на супер-ЭВМ» (2019-2020) — исполнитель.</p>
          <p>РФФИ 21-51-10003 «Обратное математическое моделирование в эпидемиологии» (2021-2022) — исполнитель.</p>
          <p>Грант Президента Российской Федерации МК-4994.2021.1.1 «Агентное моделирование и прогнозирование распространения коронавирусной эпидемии в регионах Российской Федерации с учетом анализа эффективности карантинных мер» (2021-2022) — исполнитель.</p>
          <p>РНФ 23-71-10068 «Разработка интеллектуальной программной системы мониторинга и моделирования взаимосвязанных эпидемиологических и экономических процессов в Российской Федерации» (2023-2026) — исполнитель.</p>
        </div>
        <hr></hr>
        </div>
        </Container>
        

    <Footer />
    </>
    
    )}